import { useEffect } from "react";
import { useAppStore } from "../../../hooks/useAppStore";
import { scrollToElementPosition } from "../../../utils/scrollToElementPosition";
import { ExpandableId } from "../../../data/expandable";

const Scroller = () => {
  const openExpandable = useAppStore((s) => s.openExpandable);

  useEffect(() => {
    const id = window.location.hash.slice(1); // remove the '#'
    if (id) {
      const el = document.getElementById(id);
      if (el) {
        const elTop = el.getBoundingClientRect().top;
        scrollToElementPosition(elTop);
        if (Object.values(ExpandableId).includes(id as ExpandableId))
          openExpandable(id as ExpandableId);
      }
    }
  }, []);
};

export default Scroller;
